<template>
	<!-- 认证列表 -->
	<div class="cert_apply">
		<div class="el-content mb12">
			<div class="kd-pacel">检索</div>
			<div class="mb12">
				<span class="f12">认证类型：</span>
				<a-select v-model:value="search.cert_id" placeholder="认证类型" style="width: 200px;">
					<a-select-option value="0">请选择认证类型</a-select-option>
					<a-select-option :value="v.cert_id" v-for="(v,i) in cert" :key="i">{{v.cert_name}}</a-select-option>
				</a-select>
			</div>
			<div class="mb12">
				<span class="f12">支付状态：</span>
				<a-radio-group v-model:value="search.pay_status">
					<a-radio value="0">未支付</a-radio>
					<a-radio value="1">已支付</a-radio>
				</a-radio-group>
			</div>
			<div class="mb12">
				<span class="f12">审核状态：</span>
				<a-radio-group v-model:value="search.status">
					<a-radio value="0">待审核</a-radio>
					<a-radio value="1">已审核</a-radio>
				</a-radio-group>
			</div>
			<a-button style="margin-left: 60px;" type="primary" @click="getCertApply(1,info.limit)">查询</a-button>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'认证类型',dataIndex:'cert_content.cert_name'},
				{title:'认证费用',dataIndex:'cert_amount',slots:{customRender:'cert_amount'}},
				{title:'提交时间',dataIndex:'create_time'},
				{title:'订单数量',dataIndex:'shop_cert_order_count'},
				{title:'审核状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'支付状态',dataIndex:'pay_status',slots:{customRender:'pay_status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #cert_amount="{record}">
					<span class="kdred">￥{{record.cert_content.cert_amount}}</span>
				</template>
				<template #status="{record}">
					<a-tag color="#999" v-if="record.status == 0">待审核</a-tag>
					<a-tag color="#00CC66" v-if="record.status == 1">已通过</a-tag>
					<a-tag color="#FF0066" v-if="record.status == 2">已驳回</a-tag>
					<a-tag color="#FF9900" v-if="record.status == 3">重新提交</a-tag>
				</template>
				<template #pay_status="{record}">
					<span v-if="record.cert_content.cert_amount > 0 ">
						<a-tag :color="record.pay_status == 1 ?'#00CC66':'#999'">{{ record.pay_status == 1 ?'已支付':'未支付'}}</a-tag>
					</span>
					<a-tag color="#0099FF" v-else>免费认证</a-tag>
				</template>
				<template #action="{record}">
					<kd-button title="认证审核" icon="ri-checkbox-multiple-blank-line"  @click="editCertApply(record)"></kd-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getCertApply(info.page,e)}"
					@change="(e)=>{getCertApply(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.check" title="认证审核" @ok="checkCert" @cancel="show.check = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item :label="v.label" v-for="(v,i) in submitInfo" :key="i">
					<span v-if="v.input=='text'">{{v.value || '暂未填写'}}</span>
					<div v-if="v.input== 'image'">
						<a-image style="width: 100px;" :src="v.value"  :preview-src-list="[v.value]"></a-image>
					</div>
				</a-form-item>
				<a-form-item label="审核状态">
					<a-radio-group v-model:value="form.status">
						<a-radio :value="1">通过申请</a-radio>
						<a-radio :value="2">驳回申请</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="驳回理由" v-if="form.status == 2">
					<a-input type="textarea" v-model:value="form.refuse_desc"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>	
	</div>
</template>
<script>
import shopModel from '@/api/addons/shop.js'
import { reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			cert:[],
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				cert_id:null,
				status:'',
				pay_status:''
			},
			show:{
				check:false
			},
			submitInfo:null,
			form:{
				submit_id:0,
				status:0,
				pay_status:0,
				refuse_desc:''
			}
		})
		getCertApply(1,_d.info.limit)
		shopModel.getShopCert(1,999,res=>_d.cert = res.list)
		function getCertApply(page,limit){
			shopModel.getShopCertApply(page,limit,_d.search,res=>_d.info = {limit,...res})
		}

		function editCertApply(row){
			_d.submitInfo = row.cert_content.cert_form.form
			_d.show.check = true
			_d.form.submit_id = row.id
			_d.form.pay_status = row.pay_status
			_d.form.status = row.status
			_d.form.refuse_desc = row.refuse_desc
		}

		const checkCert = ()=>shopModel.auditShopCertApply(_d.form,()=>{
			_d.show.check = false
			getCertApply(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			getCertApply,
			editCertApply,
			checkCert
		}
	}
}
</script>

<style>
</style>
