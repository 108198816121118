import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import moment from 'moment'
import router from '@/router'
class Shop{
    /**
     * 获取店铺列表数据
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShop(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.key ) param.key = extra.key
        if( extra && extra.status >=0 ) param.status = extra.status
        common.getDataList("getShopList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑店铺信息
     * @param {object} param
     */
    addOrEditShop(param,jump=true){
        if( !tool.returnMessage(param.name,"请输入店铺名称")) return
        if( !tool.returnMessage(param.mobile,"请输入店铺手机号")) return
        $post("createOrUpdateShop",param).then(()=>{
            tool.message("保存成功")
            if( jump )router.go(-1)
        })
    }

    /**
     * 获取店铺详情
     * @param {number} shop_id
     * @param {function} fn
     */
    getShopDetail(shop_id,fn){
        $post('getShopDetail',{shop_id}).then(res=>fn(res.data)).catch(()=>{})
    }

    /**
     * 获取多商户申请列表
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShopApply(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = key
            if( extra.apply_status !=3 ) param.apply_status = extra.apply_status
        }
        common.getDataList("getApplyList",param).then(res=>fn(res))
    }

    /**
     * 获取入驻申请详情
     * @param {number} id
     * @param {function} fn
     */
    getShopApplyDetail(id,fn){
        $post('getApplyDetail',{id}).then(res=>fn(res.data))
    }

    /**
     * 入驻审核
     * @param {object} param
     * @returns
     */
    auditShopApply(param){
        if( param.apply_status == 2 ){
            if( param.reason == '' || param.reason == null ){
                tool.message('请填写驳回申请理由',"warning");
                return
            }
            $post('refuseApply',{ id:param.id,reason:param.reason }).then(()=>{
                tool.message("操作成功");
            })
        }
        if(param.apply_status == 3 ){
            $post('agreeApply',{ id:param.id }).then(()=>{
                tool.message("操作成功");
            })
        }
    }

    /**
     * 设置店铺解封状态
     * @param {number} shop_id  店铺id
     * @param {string} end_time 解封时间
     * @param {function} fn
     */
    setShopForbid(shop_id,end_time,fn){
        let param = {shop_id,end_at:moment(end_time).format("YYYY-MM-DD HH:mm:ss")}
        $post('setShopForbid', param).then(res=>{
            tool.message('设置成功')
            fn(res.data)
        })
    }

    /**
     * 批量操作店铺商品上下架状态
     * @param {number} shop_id
     * @param {string} model    goods land adopt live
     * @param {number} status
     * @param {function} fn
     */
    changeShopGoodsStatus(shop_id,model,status){
        $post('changeShopGoods',{model,status,shop_id}).then(()=>{
        	tool.message('操作成功')
        })
    }

    /**
     * 获取店铺缴纳的保证金记录
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getBailTrade(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.time.length > 0 ){
                param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
            }
            if( extra.trade_status ) param.trade_status = extra.trade_status
            if( extra.key ) param.key = extra.key
        }
        common.getDataList("getBailTradeList",param).then(res=>fn(res))
    }

    /**
     * 获取总的保证金信息
     * @param {function} fn
     */
    getBailMoney(fn){
        $post('getBailMoney').then(res=>fn(res.data))
    }

    /**
     * 修改商户信息
     * @param {string} sence password 修改密码 other修改其他信息
     * @param {number} user_id
     * @param {string} password
     * @param {function} fn
     */
    updateShopUser(sence,param,fn){
        if( sence == 'password'){
            if( !tool.returnMessage(param.password,'请输入密码')) return
            $post('changeShopUser',param).then(res=>{
                tool.message("密码修改成功")
                fn(res.data)
            })
        }

        if( sence == 'other'){
            $post('changeShopUser',param).then(res=>{
                tool.message("保存成功")
                fn(res.data)
            })
        }
    }

    /**
     * 恢复农场删除用户
     * @param {number} shop_id
     * @param {function} fn
     */
    rebackDelStore(shop_id,fn){
        tool.confirm("确认恢复该商户吗?").then(()=>{
            $post("RedoDeleteByShop",{shop_id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            }).catch(()=>{})
        }).catch(()=>{})
    }

    /**
     * 获取商户诚信等级信息
     * @param {object} param
     * @param {function} fn
     */
    getShopLevel(page,limit,fn){
        let param = { page,limit }
        common.getDataList("getShopLevelList",param).then(res=>fn(res))
    }

    /**
     * 删除店铺诚信等级
     * @param {number} level_id
     * @param {function} fn
     */
    deleteShopLevel(level_id,fn){
        tool.confirm("确认要删除等级信息吗？").then(()=>{
            $post("delShopLevel",{level_id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 新增或编辑店铺诚信等级
     * @param {object} param
     * @param {function} fn
     */
    addOrEditShopLevel(param,fn){
        if( !param.level_id ) delete param.level_id
        $post("setShopLevel",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 获取认证表单信息
     * @param {number} page
     * @param {number} limit
     * @param {function} fn
     */
    getShopCert(page,limit,fn){
        let param = { page ,limit }
        common.getDataList("getShopCertList",param).then(res=>fn(res))
    }

    /**
     * 获取认证表单详情
     * @param {number} cert_id
     * @param {function} fn
     */
    getShopCertDetail(cert_id,fn){
        $post('getShopCert',{cert_id}).then(res=>fn(res.data.model))
    }

    /**
     * 删除店铺认证表单
     * @param {number} cert_id
     * @param {function} fn
     */
    deleteShopCert(cert_id,fn){
        tool.confirm("确认要删除认证信息吗？").then(()=>{
            $post("delShopCert",{cert_id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 新增或编辑店铺认证表单信息
     * @param {object} param
     */
    addOrEditShopCert(param){
        if( !tool.returnMessage(param.cert_name,"请填写认证名称")) return
        if( !param.cert_id ) delete param.cert_id
        $post("setShopCert",param).then(res=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取认证表单的模板
     * @param {string} template_id
     * @param {function} fn
     */
    getShopCertTemplate(template_id,fn){
        if(template_id == 'custom') return
        $post("getShopCertTemplate",{cat:template_id}).then(res=>fn(res.data.template))
    }

    /**
     * 获取认证申请列表数据
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShopCertApply(page,limit,extra,fn){
        let param = {page,limit}
        if( extra ){
            if( extra.cert_id ) param.cert_id = extra.cert_id
            if( extra.status ) param.status = extra.status
            if( extra.pay_status ) param.pay_status = extra.pay_status
        }
        common.getDataList("getShopCertSubmitList",param).then(res=>{
            res.list.forEach(item=>item.cert_content = JSON.parse(item.cert_content))
            fn(res)
        })
    }
    /**
     * 认证申请审核
     * @param {object} param
     * @param {function} fn
     */
    auditShopCertApply(param,fn){
        if( param.status == 2 && !param.refuse_desc ){
            tool.message("请输入驳回审核原因")
            return
        }
        $post("setShopCertSubmit",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 获取店铺认证订单
     * @param {number}} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShopCertOrder(page,limit,extra,fn){
        let param = { page ,limit }
        if( extra.cert_id ) param.cert_id = extra.cert_id
        if( extra.shop_id ) param.shop_id = extra.shop_id
        if( extra.order_sn ) param.order_sn = extra.order_sn
        if( extra.pay_sn ) param.pay_sn = extra.pay_sn
        if( extra.pay_status ) param.pay_status = extra.pay_status
        common.getDataList("getShopCertOrderList",param).then(res=>fn(res))
    }

    /**
     * 获取保证金等级
     * @param {number} page
     * @param {number} limit
     * @param {function} fn
     */
    getBondLevel( page,limit ,fn){
        let param = { page,limit}
        common.getDataList("getShopBailLevelList",param).then(res=>fn(res))
    }

    /**
     * 删除保障金等级
     * @param {number} level_id
     * @param {function} fn
     */
    deleteBondLevel(level_id,fn){
        tool.confirm("确认要删除保证金等级信息吗？").then(()=>{
            $post("delShopBailLevel",{level_id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取保证金详情
     * @param {number} level_id
     * @param {function} fn
     */
    getBondLevelDetail(level_id,fn){
        $post('getShopBailLevel',{ level_id }).then(res=>fn(res.data.model))
    }

    /**
     * 新增或编辑保证金信息
     * @param {object} param
     */
    addOrEditBondLevel(param){
        if( !param.level_id ) delete param.level_id
        $post("setShopBailLevel",param).then(res=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取保证金订单
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getBondOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.shop_id ) param.shop_id = extra.shop_id
            if( extra.order_sn ) param.order_sn = extra.order_sn
            if( extra.pay_sn ) param.pay_sn = extra.pay_sn
            if( extra.pay_type ) param.pay_type = extra.pay_type
            if( extra.pay_status ) param.pay_status = extra.pay_status
            if( extra.time.length > 0 ){
                param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
                param.end_at = moment(extra.time[1]).format("YYYY-MM-DD")
            }
        }
        common.getDataList("getShopBailOrderList",param).then(res=>fn(res))
    }

    /**
     * 获取保证金账单
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShopBondBill(page,limit,extra,fn){
        let param = { page,limit}
        if( extra &&extra.shop_id ) param.shop_id = extra.shop_id
        common.getDataList("getShopBailBillList",param).then(res=>fn(res))
    }

    /**
     * 添加保证金账单
     * @param {object} param
     * @param {function} fn
     */
    addShopBondBill(param,fn){
        if( !tool.returnMessage(param.shop_id,"请选择店铺")) return
        if( !tool.returnMessage(param.option_type,"请选择操作类型")) return
        if( !tool.returnMessage(param.option_desc,"请填写描述")) return
        $post("addShopBailBill",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 退还店铺保证金
     * @param {number} shop_id 店铺id
     * @param {number} amount 退还金额
     * @param {function} fn
     */
    refundShopBond(shop_id,amount,fn){
        tool.confirm("确认退还保证金吗？退还后保证金会回到商家钱包中").then(()=>{
            $post('refundShopBail',{shop_id,amount}).then(res=>{
                tool.message('退还操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取店铺余额日志
     * @param {number} page
     * @param {function} limit
     * @param {object} extra
     * @param {function} fn
     */
    getShopBankLog(page,limit,extra,fn){
        let param = {page,limit}
        if( extra ){
            if( extra.user_id ) param.user_id = extra.user_id
            if( extra.type ) param.type = extra.type
        }
        common.getDataList("getBankLog",param).then(res=>fn(res))
    }

    /**
     * 获取店铺金额
     * @param {number}} shop_id
     * @param {function} fn
     */
    getShopMoney(shop_id,fn){
        $post('getShopMoney',{id:shop_id}).then(res=>fn(res.data.money))
    }

    /**
     * 获取当前登录的商户信息
     * @param {function} cb
     */
    getShopUserDetail(cb){
        $post('getShopUserDetail').then(res=>cb(res.data))
    }

    getShopStatistics(fn) {
        $post("statistics",{}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 商户提现
     * @param {object} param
     * @param {function} cb
     * @returns
     */
    shopWithdraw(param,cb){
        if( param.method == 0 && (param.name =='' || param.account =='') ){
            tool.message("请完整地填写真实姓名和支付宝账号~","warning")
            return
        }
        $post('withdrawal',param).then(res=>{
            tool.message("提现申请已提交")
            cb(res.data)
        },()=>{})
    }

    getGlod(cb){
        $post('getGold').then(res=>cb(res.data))
    }

    /**
     * 修改商户密码
     * @param {object} param
     * @returns
     */
    updateShopUserPwd(param){
		if( !tool.returnMessage(param.password,'请输入新密码')) return
		if( !tool.returnMessage(param.re_password,'请再次输入新密码')) return
        if( param.password !=param.re_password ){
            tool.message("两次输入密码不一致","warning")
            return
        }
        $post('updateUserPassword',{password:param.password}).then(()=>{
            tool.message("密码修改成功")
        },()=>{})
    }

    getWxAuthOpendid(type,cb){
        // 0 平台通知
        $post('getOpenidByqrcode',{type}).then(res=>cb(res.data))
    }


}
const shopModel = new Shop()
export default shopModel
